import { render, staticRenderFns } from "./modalCambiarVigencia.vue?vue&type=template&id=0be116e8"
import script from "./modalCambiarVigencia.vue?vue&type=script&lang=js"
export * from "./modalCambiarVigencia.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports